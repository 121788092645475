.calender-section-container{
  @apply flex w-full h-full flex-col gap-2
}

.cs-header-section{
  @apply flex justify-start gap-5 items-center
}

.cs-weak-change-buttons{
  @apply flex items-center gap-2 cursor-pointer transition
}

.cs-weak-change-button{
  @apply p-0.5 bg-blue-400 active:bg-blue-500 flex justify-center items-center text-white rounded-md
}

.cs-each-weak{
  @apply flex w-full justify-around sm:justify-center sm:gap-8 items-center text-sm font-semibold
}

.cs-each-weak-day{
  @apply flex flex-col items-center gap-1 w-fit h-fit
}

.next-weak-section{
  @apply flex w-full bg-[#1B2433] border rounded-md  overflow-hidden shadow-md relative
}

.next-weak-section-bgImage{
  @apply h-[77px] w-full
}

.nw-section-content{
  @apply flex  w-full h-full justify-center items-center absolute text-white gap-10
}

.nw-section-button {
  @apply bg-blue-600 flex justify-center items-center text-xs rounded-lg px-2 py-0.5
}