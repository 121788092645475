.recent-patients-container{
    @apply flex h-fit items-center w-full
}

.rp-header{
    @apply flex w-full h-fit justify-between
}

.rp-link{
    @apply flex text-blue-400 items-center gap-2 cursor-pointer hover:text-blue-500 transition
}

.rp-table-container{
    @apply flex flex-col w-full mt-3
}

.rp-head-row{
    @apply px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase 
}